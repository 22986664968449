import React, {useState,
//		useRef,
//		createContext,
} from 'react';
import Helmet from 'react-helmet';
import settings from '../../settings';

function Layout({children,className}) {

	const [toggled, setToggled]	=	useState(false);

	const handleToggleSidebar = function(value){
		setToggled(value);
	};

	return (
	<div className={`app ${toggled?'menu_showing':''}`}>
		<Helmet>
				<meta charSet="utf-8" />
				<title>GoogleAuthenticator.dev</title>
				<link rel="canonical" href={settings.host.url} />
				<meta
					name="description"
					content="GoogleAuthenticator.dev"
				/>
				<script type="application/ld+json"></script>
		</Helmet>
		<main>
			{children}
		</main>
	</div>
	);
}

export default Layout;